import React, { useEffect, useState, useRef } from "react";
import ChatIndexCard from "./ChatIndexCard";
import "./root.css"
import ChatMessageItemCustomer from "./ChatMessageItem";
import ChatMessageItemUser from "./ChatMessageItemUser";
import { Icon, TextField } from "@material-ui/core"
import { TicketApi } from "@unity/components"
import { chatIndexByTicketArray, chatShow, messageStore } from "./api/chat";

export default function index({ pusher, channel, name }) {

    const [active, setActive] = useState(false)
    const prevActive = usePrevious(active);
    const [chats, setChats] = useState([])
    const [chatMessages, setChatMessages] = useState([])
    const [openTickets, setOpenTickets] = useState(false)
    const [ticketArray, setTicketArray] = useState([])
    const [currentTicket, setCurrentTicket] = useState(false)
    const [message, setMessage] = useState("");

    const handleChatOpen = async () => {
        if (active) {
            const res = await TicketApi.getTicketRecord(active.reference_id)
            setCurrentTicket(res.data);
        }
    }

    function usePrevious(value) {
        // The ref object is a generic container whose current property is mutable ...
        // ... and can hold any value, similar to an instance property on a class
        const ref = useRef();
        // Store current value in ref
        useEffect(() => {
            ref.current = value;
        }, [value]); // Only re-run if value changes
        // Return previous value (happens before update in useEffect above)
        return ref.current;
    }

    const handleMessageSend = async () => {
        var obj = {
            chat_id: active.id,
            body: message,
            is_customer: false,
            username: name,
            send_email: true,
            contact_email: currentTicket.clientEmail
        }
        console.log(obj)
        await messageStore(obj);
        setMessage("")
    }

    const getOpenTickets = async (id = false) => {
        var arr = [];
        const res = await TicketApi.getTicketOpen();
        setOpenTickets(res.data)
        res.data.map((item) => arr.push(item.id));
        if (id) {
            arr.push(id);
        }
        const ticketArray = await chatIndexByTicketArray({ reference_array: arr })
        setChats(ticketArray.data)
        setTicketArray(arr)
    }

    const handleTicketClose = async (id) => {
        const newTicketArray = [...ticketArray];
        newTicketArray.filter(function (item) {
            return item !== id;
        })
        const ticketArray = await chatIndexByTicketArray({ reference_array: newTicketArray })
        setChats(ticketArray.data)
        setTicketArray(newTicketArray)
    }

    useEffect(async () => {
        getOpenTickets();
    }, [])


    useEffect(() => {
        handleChatOpen()
    }, [active])

    useEffect(() => {
        if (prevActive) {
            pusher.unsubscribe(`chat-ticket-${prevActive.id}`);
            console.log("unsub")
        }
        channel.bind(`chat-ticket-${active.id}`, async function (e) {
            const data = await chatShow(active.id);
            setChatMessages(data.data.messages)
        });

    }, [active])


    useEffect(() => {
        channel.bind('TicketCreateEvent', (e) => {
            getOpenTickets(e.id)
        })
        channel.bind('TicketClosedEvent', async (e) => {
            handleTicketClose(e.ticket.id)
        });
    }, [])

    return (
        <div style={{ background: '#f2f2f2', height: '92vh' }}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-3" style={{ height: "89vh", padding: 0 }}>
                        <div className="block" style={{ height: "100%", boxShadow: "none", overflowY: 'scroll', background: '#3b3bb5' }}>
                            {chats.map((item, key) => {
                                return <ChatIndexCard data={item} setChatMessages={setChatMessages} setActive={setActive} openTickets={openTickets} active={active.reference_id === item.id} />;
                            })}
                        </div>
                    </div>

                    <div className="col-lg-6" style={{ height: "89vh", paddingLeft: 0 }}>
                        {active &&
                            <div className="block" id="chat-window" style={{ height: "100%", padding: 20, boxShadow: "none", overflowY: "scroll", paddingBottom: 90, paddingTop: 10 }}>
                                <p className="chat-title" style={{ marginBottom: 30 }}>Ticket {active.subject} <i style={{ "font-size": "12px" }}>ref: {active.reference_id}</i></p>
                                {active && chatMessages.map((item, key) => {
                                    if (item.is_customer) {
                                        return <ChatMessageItemCustomer data={item} />
                                    } else {
                                        return <ChatMessageItemUser data={item} />
                                    }
                                })}

                                <div className="chat-actions" style={{ overflowY: "scroll", overflowX: "hidden" }}>
                                    <div className="row">
                                        <div className="col-lg-10">
                                            <TextField onChange={(e) => setMessage(e.target.value)} value={message} type="text" placeholder="Type message" multiline className="input-message" />
                                        </div>
                                        <div className="col-lg-2">
                                            <button onClick={() => handleMessageSend()}>Send</button>
                                        </div>
                                    </div>
                                </div>

                            </div>}
                    </div>
                    {active &&

                        <div className="col-lg-3" style={{ height: "89vh", padding: 0}}>
                            <div className="block" style={{ height: "60%", padding: 20, borderRadius: 10 }}>
                                <p className="chat-title" style={{ marginBottom: 20 }}>Contact Information</p>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 10 }}>
                                        <div style={{ flex: 1 }}><Icon>email</Icon></div>
                                        <div style={{ flex: 4 }}>{currentTicket.clientEmail}</div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ flex: 1 }}><Icon>phone</Icon></div>
                                        <div style={{ flex: 4 }}>{currentTicket.clientNumber}</div>
                                    </div>
                                </div>
                                <p className="chat-title" style={{ marginTop: 15 }}>Ticket Information</p>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 10 }}>
                                        <div style={{ flex: 2 }}>Name:</div>
                                        <div style={{ flex: 4 }}>{currentTicket.ticket_name}</div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 10 }}>
                                        <div style={{ flex: 2 }}>Reported By: </div>
                                        <div style={{ flex: 4 }}>{currentTicket.reported_by}</div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 10 }}>
                                        <div style={{ flex: 2 }}>Company: </div>
                                        <div style={{ flex: 4 }}>{currentTicket.clientName}</div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 10 }}>
                                        <div style={{ flex: 2 }}>Notes: </div>
                                        <div style={{ flex: 4 }}><button onClick={() => el()}>View</button></div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="block" style={{ height: "30%", borderRadius: 10 }}>
                                <div class="mapouter"><div class="gmap_canvas">
                                    <iframe width="330" height="205" id="gmap_canvas" src="https://maps.google.com/maps?q=2880%20Broadway,%20New%20York&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                                    <a href="https://www.whatismyip-address.com/divi-discount/"></a>
                                </div>

                                </div>
                            </div> */}
                        </div>
                    }
                </div>
            </div>

        </div >
    );
}
